<template>
  <section class="page-content">
    <SearchPageContentFilter />
    <SearchFilteredProducts />
    <!--<b-link
      href="#"
      class="load-more d-block text-center font-weight-normal py-25"
      ><i class="lnr lnr-hourglass mr-6"></i>Meer laden</b-link
    >-->

    <!-- Pagination -->
    <div
        v-if="totalProducts > 0"
        class="pb-30 pagination-header category-right-top-list-header mt-0"
      >
        <div class="category-list-left">
          {{ totalProducts }} {{ $t("result") }} | &nbsp; {{ $t("sort_by") }}
        </div>
        <div class="category-list-right pagination-wrap">
          <b-pagination
            :value="currentPage"
            @change="changePage($event)"
            :per-page="prodsPerPage"
            :total-rows="totalProducts"
            :limit="3"
            :prev-class="{ 'd-none': currentPage == 1 }"
            :next-class="{
              'd-none': currentPage == Math.ceil(totalProducts / prodsPerPage),
            }"
          >
            <template #page="{ page }">
              <b-link @click="goToPage(page, $event)" ref="paginationLink">
                {{ page }}
              </b-link>
            </template>
          </b-pagination>
        </div>
      </div>
  </section>
</template>

<script>
import SearchPageContentFilter from "@/esf_lommel_dt1_europe/core/components//SearchPageContentFilter";
import SearchFilteredProducts from "@/esf_lommel_dt1_europe/core/components//SearchFilteredProducts";
export default {
  name: "SearchPageContent",
  components: {
    SearchPageContentFilter,
    SearchFilteredProducts,
  },

  computed: {
    currentPage() {
      return +this.$store.getters["search/getCurrentPage"];
    },
    prodsPerPage() {
      return +this.$store.getters["search/getProductsPerPage"];
    },
    totalProducts() {
      return +this.$store.getters["search/getTotalProducts"];
    },
  },

  methods: {
    changePage(pageNo) {
      localStorage.setItem("v-remember-scroll-position", window.scrollY);
      localStorage.setItem("v-remember-scroll-page", window.location.pathname);

      this.$store.commit("search/setCurrentPage", pageNo);

      const { path } = this.$route;
      let query = Object.assign({}, this.$route.query);
      let queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);

      searchParams.forEach((value, key) => {
        query[key] = value;
      });
      if (pageNo != 1) {
        query["page"] = pageNo;
      } else {
        delete query["page"];
      }

      window.history.replaceState(
        history.state,
        null,
        path +
          "?" +
          Object.keys(query)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
              );
            })
            .join("&")
      );
    },

    goToPage(page) {
      localStorage.setItem("v-remember-scroll-position", window.scrollY);
      localStorage.setItem("v-remember-scroll-page", window.location.pathname);
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.load-more {
  font-size: 16px;
}
/* category-filter */
.category-right-top-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  .category-list-left,
  .category-list-right {
    display: inline-block;
  }
  .category-list-left {
    font-size: 18px;
    margin-bottom: 0;
    @include media("screen", "<=widescreen") {
      font-size: 16px;
    }
    @include media("screen", "<=desktop") {
      font-size: 14px;
    }
    @include media("screen", "<=tablet") {
      font-size: 12px;
      width: 100%;
    }
  }
  .category-list-right,
  .category-list-right {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    @include media("screen", "<=widescreen") {
      font-size: 16px;
    }
    @include media("screen", "<=desktop") {
      font-size: 14px;
    }
    float: right;
  }
  @include media("screen", "<=tablet") {
    .category-list-right {
      display: none !important;
    }
  }
}
</style>
